import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import "./Chat.css";
import ScrollToBottom from "react-scroll-to-bottom";
import { io } from "socket.io-client";
import LoaderAnimation from "../LoaderAnimation/LoaderAnimation";
import axios from "axios";
import MessageCard from "../MessageCard/MessageCard";

const Chat = () => {
	const currentQuest = useSelector((state) => state.quest);
	const currentQuestStage = useSelector((state) => state.questStage);
	const [socket, setSocket] = useState(null);
	const [message, setMessage] = useState("");
	const [messageList, setMessageList] = useState([]);
	const [sendingMessage, setSendingMessage] = useState(false);
	const messageListRef = useRef(null);

	useEffect(() => {
		if (messageListRef.current) {
			messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
		}
	}, [messageList]);

	const handleMessageSending = () => {
		if (socket) {
			setSendingMessage(true);
			const data = {
				content: message,
				questId: currentQuest._id,
			};
			setMessage("");
			setMessageList((prevMessages) => [...prevMessages, { messageType: "user", message }]);
			socket.emit("sendMessage", data);
			setSendingMessage(false);
		}
	};

	const fetchMessages = async () => {
		const URL = `${process.env.REACT_APP_BACKEND_URL}/api/messages/${currentQuest?._id}/chatting`;
		console.log(URL);
		axios
			.get(URL)
			.then((response) => {
				setMessageList(response.data.data);
				console.log(messageList);
			})
			.catch(() => {});
	};

	useEffect(() => {
		if (socket) {
			socket.disconnect();
			setSocket(null);
		}
		if (currentQuest && currentQuestStage === "started") {
			fetchMessages();
			const URL = process.env.REACT_APP_BACKEND_URL;
			const newSocket = io(URL, {
				debug: true,
				query: {
					questId: currentQuest?._id,
				},
			});

			setSocket(newSocket);
		} else {
			socket.disconnect();
			setSocket(null);
		}

		return () => {
			if (socket) {
				socket.disconnect();
				setSocket(null);
			}
		};
	}, [currentQuest, currentQuestStage]);

	useEffect(() => {
		if (socket) {
			socket.on("receiveMessage", (msg) => {
				setMessageList((prevMessages) => [...prevMessages, msg]);
			});
			socket.on("connect_error", (error) => {
				console.log(`Socket.io connection error: ${error}`);
			});
			socket.on("reconnect", () => {
				console.log("Socket.io reconnected");
			});
		}
	}, [socket]);

	return (
		<div className="chatAreaContainer">
			<ScrollToBottom className="messageListContainer">
				{messageList.map((msg) => {
					let className = "messageCard alignRight";
					if (msg.messageType !== "user") {
						className = "messageCard alignLeft";
					}
					return (
						<>
							<div className={className}>
								<MessageCard message={msg} />
							</div>
						</>
					);
				})}
			</ScrollToBottom>
			<div className="messageTypingArea">
				<div className="typingArea">
					<textarea
						value={message}
						placeholder="Type your message"
						onChange={(e) => setMessage(e.target.value)}
					/>
				</div>
				{sendingMessage ? (
					<>
						<LoaderAnimation />
					</>
				) : (
					<div className="sendButton" onClick={handleMessageSending}>
						Send
					</div>
				)}
			</div>
		</div>
	);
};

export default Chat;
