import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import ScrollToBottom from "react-scroll-to-bottom";
import { io } from "socket.io-client";
import LoaderAnimation from "../LoaderAnimation/LoaderAnimation";
import MessageCard from "../MessageCard/MessageCard";
import "./Coach.css";

const Coach = () => {
	const [messageList, setMessageList] = useState([]);
	const [message, setMessage] = useState("");
	const [sendingMessage, setSendingMessage] = useState(false);
	const [socket, setSocket] = useState(null);
	const [fetchingMessages, setFetchingMessages] = useState(false);

	const currentQuest = useSelector((state) => state.quest);
	const currentQuestStage = useSelector((state) => state.questStage);

	const handleMessageSending = () => {
		if (socket && currentQuest && currentQuestStage === "coaching") {
			setSendingMessage(true);
			const data = {
				content: message,
				questId: currentQuest._id,
			};
			setMessage("");
			setMessageList((prevMessages) => [...prevMessages, { messageType: "user", message }]);
			socket.emit("sendCoachingMessage", data);
			setSendingMessage(false);
		}
	};

	const connectAndEmitMessage = () => {
		const URL = process.env.REACT_APP_BACKEND_URL;
		const newSocket = io(URL, {
			debug: true,
			query: {
				questId: currentQuest?._id,
			},
		});
		newSocket.on("connect", () => {
			console.log("Socket connected. Emitting message.");
			newSocket.emit("sendCoachingMessage", { questId: currentQuest._id });
			setSocket(newSocket);
		});
	};

	const fetchMessages = () => {
		setFetchingMessages(true);
		const URL = `${process.env.REACT_APP_BACKEND_URL}/api/messages/${currentQuest._id}/coaching`;
		axios
			.get(URL)
			.then((response) => {
				if (response.data.data.length > 0) {
					setMessageList(response.data.data);
				} else {
					if (socket && socket.connected) {
						console.log("Emitting a message");
						console.log(socket);
						socket.emit("sendCoachingMessage", { questId: currentQuest._id });
					} else {
						connectAndEmitMessage();
					}
				}
				setFetchingMessages(false);
			})
			.catch((error) => {
				setFetchingMessages(false);
			});
	};

	useEffect(() => {
		if (socket) {
			socket.disconnect();
			setSocket(() => null);
		}
		if (currentQuest && currentQuestStage === "coaching" && (!socket || !socket?.connected)) {
			setSocket(null);
			const URL = process.env.REACT_APP_BACKEND_URL;
			const newSocket = io(URL, {
				debug: true,
				query: {
					questId: currentQuest?._id,
				},
			});
			setSocket(newSocket);
		}

		fetchMessages();

		return () => {
			if (socket) {
				socket.disconnect();
				setSocket(() => null);
			}
		};
	}, [currentQuestStage]);

	useEffect(() => {
		if (socket && socket.connected) {
			socket.on("receiveCoachingMessage", (msg) => {
				setMessageList((prevMessages) => [...prevMessages, msg]);
			});
			socket.on("connect_error", (error) => {
				console.log(`Socket.io connection error: ${error}`);
			});
			socket.on("reconnect", () => {
				console.log("Socket.io reconnected");
			});
		}
	}, [socket]);

	return (
		<div className="chatAreaContainer">
			<ScrollToBottom className="messageListContainer">
				{messageList.length > 0 ? (
					<>
						{messageList.map((msg) => {
							let className = "messageCard alignRight";
							if (msg.messageType !== "user") {
								className = "messageCard alignLeft";
							}
							return (
								<>
									<div className={className}>
										<MessageCard message={msg} />
									</div>
								</>
							);
						})}
					</>
				) : (
					<>
						<MessageCard
							message={{ message: "Please wait while we start coaching", messageType: "system" }}
						/>
					</>
				)}
			</ScrollToBottom>
			<div className="messageTypingArea">
				<div className="typingArea">
					<textarea
						value={message}
						placeholder="Type your message"
						onChange={(e) => setMessage(e.target.value)}
					/>
				</div>
				{messageList.length === 0 ? (
					<>
						<LoaderAnimation />
					</>
				) : (
					<div className="sendButton" onClick={handleMessageSending}>
						Send
					</div>
				)}
			</div>
		</div>
	);
};

export default Coach;
