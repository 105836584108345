import React, { useEffect, useState } from "react";

import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const StartQuestPopup = ({ open, handleOpen, startQuest }) => {
	const handleClose = () => {
		handleOpen(false);
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperProps={{
				component: "form",
				onSubmit: (event) => {
					event.preventDefault();
					const formData = new FormData(event.currentTarget);
					const formJson = Object.fromEntries(formData.entries());
					const email = formJson.email;
					console.log(email);
					handleClose();
				},
			}}
		>
			<DialogTitle>Welcome to Taara Skill Quest!</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Before you start, we need your consent for data handling. We collect your alias, age, occupation,
					and years of experience to improve and evaluate our training scenarios. This information is stored
					securely in the EU and is not linked to your real identity. We do not share your data with third
					parties, except for anonymized data for research purposes with Lancaster University Leipzig until
					the end of 2024. You can contact us at hello@taara.quest for any inquiries or to withdraw your
					consent. By clicking "I Agree", you allow us to use your data as described.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>
				<Button type="submit" onClick={startQuest}>
					I Agree
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default StartQuestPopup;
