import { React } from "react";
import { CircleLoader } from "react-spinners";

const LoaderAnimation = (prop) => {
	const size = prop.size || 20;
	const color = prop.color || "#3f5de1";
	return (
		<>
			<CircleLoader color={color} size={size} />
		</>
	);
};

export default LoaderAnimation;
