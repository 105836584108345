import { createSlice } from "@reduxjs/toolkit";

export const adminTabSlice = createSlice({
	initialState: "quest",
	name: "adminTab",
	reducers: {
		setAdminTabToQuest: (state) => {
			return "quest";
		},
		setAdminTabToPartner: (state) => {
			return "roleplay";
		},
	},
});

export const { setAdminTabToQuest, setAdminTabToPartner } = adminTabSlice.actions;
export default adminTabSlice.reducer;
