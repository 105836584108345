import React from "react";
import "./Home.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import { setQuest, unsetQuest } from "../../redux/slices/quest";
import { useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import LoaderAnimation from "../../components/LoaderAnimation/LoaderAnimation";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import Chat from "../../components/Chat/Chat";
import Coach from "../../components/Coach/Coach";
import { beginCoaching, beginQuest, completedQuest } from "../../redux/slices/questStage";
import Video from "../../components/Video/Video";
import _ from "lodash";

import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import StartQuestPopup from "../../components/StartQuestPopup/StartQuestPopup";

const Home = () => {
	const [fetchingQuest, setFetchingQuest] = useState(false);
	const [name, setName] = useState("");
	const [roleplayData, setRoleplayData] = useState([]);
	const [scenarioData, setScenarioData] = useState([]);
	const [roleplayPartnerId, setRoleplayPartnerId] = useState(null);
	const [ageRange, setAgeRange] = useState(null);
	const [gender, setGender] = useState(null);
	const [geography, setGeography] = useState(null);
	const [education, setEducation] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);
	const [scenarioId, setScenarioId] = useState(null);
	const [specificLocation, setSpecificLocation] = useState(null);
	const [specificEducation, setSpecificEducation] = useState(null);
	const [fetchingRoleplayPartners, setFetchingRoleplayPartners] = useState(false);
	const [fetchingScenarios, setFetchingScenarios] = useState(false);
	const [isEndingQuest, setIsEndingQuest] = useState(false);
	const [rating, setRating] = useState(5);
	const [coachRating, setCoachRating] = useState(5);
	const currentQuest = useSelector((state) => state.quest);
	const currentStage = useSelector((state) => state.questStage);
	const [feedback, setFeedback] = useState("");
	const [open, setOpen] = useState(false);
	const [openStartQuestDialog, setOpenStartQuestDialog] = useState(false);
	const dispatch = useDispatch();

	const ageRangeOptions = ["Under 18", "18-34", "35-54", "55 and older"];
	const genderOptions = ["Female", "Male", "Non-Binary", "Prefer not to say"];
	const locationOptions = [
		"North America",
		"South America",
		"Europe",
		"Asia",
		"Africa",
		"Middle East",
		"Oceania (Australia, New Zealand)",
		"Prefer not to say",
		"Other (please specify)",
	];
	const educationOptions = [
		"No high school diploma",
		"High school diploma or equivalent",
		"Some college or associate degree",
		"Bachelors degree",
		"Graduate degree (Master's, Professional, Doctorate)",
		"Prefer not to say",
		"Other (please specify)",
	];

	const fetchData = async () => {
		const roleplayURL = `${process.env.REACT_APP_BACKEND_URL}/api/roleplayPartners`;
		const scenarioURL = `${process.env.REACT_APP_BACKEND_URL}/api/scenario`;

		setFetchingRoleplayPartners(true);
		axios
			.get(roleplayURL)
			.then((response) => {
				setRoleplayData(response.data.data);
				setFetchingRoleplayPartners(false);
			})
			.catch((error) => {});

		setFetchingScenarios(true);
		axios
			.get(scenarioURL)
			.then((response) => {
				setScenarioData(response.data.data);
				setFetchingScenarios(false);
			})
			.catch((error) => {});
	};

	const resetAll = () => {
		setName("");
		setIsEndingQuest(false);
		setRoleplayPartnerId(null);
		setScenarioId(null);
		setFetchingQuest(false);
		setFeedback("");
		setAgeRange(null);
		setSpecificLocation(null);
		setGeography(null);
		setGender(null);
		setErrorMessage(null);
		setEducation(null);
		setSpecificEducation(null);
	};

	const handleNextStage = () => {
		dispatch(beginCoaching());
	};

	const handleEndQuest = async () => {
		const URL = `${process.env.REACT_APP_BACKEND_URL}/api/game/${currentQuest._id}/finish`;
		const data = {
			rating,
			coachRating,
			feedback,
		};

		setIsEndingQuest(true);
		axios
			.post(URL, data)
			.then((response) => {
				resetAll();
				dispatch(completedQuest());
				dispatch(unsetQuest());
			})
			.catch((error) => {
				resetAll();

				dispatch(unsetQuest());
			});
	};

	const createQuest = () => {
		setErrorMessage("");
		if (!name) {
			setErrorMessage("Please specify Name");
			return;
		}
		if (!ageRange) {
			setErrorMessage("Please specify age range");
			return;
		}
		if (!gender) {
			setErrorMessage("Please Specify gender");
			return;
		}
		if (!geography || (geography === "Other (please specify)" && !specificLocation)) {
			setErrorMessage("Please Specify Location");
			return;
		}
		if (!education || (education === "Other (please specify)" && !specificEducation)) {
			setErrorMessage("Please Specify Educational Background");
			return;
		}
		if (roleplayPartnerId && name && scenarioId) {
			const URL = `${process.env.REACT_APP_BACKEND_URL}/api/game/create`;
			const data = {
				name,
				roleplayPartnerId,
				scenarioId,
			};

			_.set(data, "gender", gender);
			_.set(data, "age", ageRange);
			if (geography === "Other (please specify)") {
				_.set(data, "geography", specificLocation);
			} else {
				_.set(data, "geography", geography);
			}

			if (education === "Other (please specify)") {
				_.set(data, "education", specificEducation);
			} else {
				_.set(data, "education", education);
			}

			setFetchingQuest(true);
			axios
				.post(URL, data)
				.then((response) => {
					setFetchingQuest(false);
					dispatch(setQuest(response.data.data));
					dispatch(beginQuest());
					setOpenStartQuestDialog(false);
				})
				.catch((error) => {
					setFetchingQuest(false);
					resetAll();
					dispatch(unsetQuest());
					setScenarioId(null);
					fetchData();
					setOpenStartQuestDialog(false);
				});
		} else {
			setErrorMessage("Please Select Roleplay Partner and Scenario");
			return;
		}
	};

	useEffect(() => {
		resetAll();
		if (currentQuest) {
			setRoleplayPartnerId(currentQuest.roleplayPartnerId);
			setScenarioId(currentQuest.scenarioId);
		} else {
			dispatch(completedQuest());
			setRoleplayPartnerId(null);
			setScenarioId(null);
		}
		fetchData();
	}, [currentQuest]);

	const getScenarios = () => {
		return scenarioData.map((scenario) => {
			if (scenario.roleplayPartnerId === roleplayPartnerId) {
				return <MenuItem value={scenario._id}>{scenario.scenarioName}</MenuItem>;
			}
		});
	};

	const handleRoleplayPartnerChange = (e) => {
		setScenarioId(null);
		setRoleplayPartnerId(e.target.value);
	};

	const handleScenarioChange = (e) => {
		setScenarioId(e.target.value);
	};

	const findRoleplayPartnerObjectById = () => {
		const partner = roleplayData.find((partner) => partner._id === roleplayPartnerId);
		return partner;
	};

	const findScenarioByScenarioId = () => {
		const scenario = scenarioData.find((scenario) => scenario._id === scenarioId);
		return scenario;
	};

	function valuetext(value) {
		setRating(value);
	}

	function coachValueText(value) {
		setCoachRating(value);
	}

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<div className="chatContainer">
				<div className="ChatContainerTitle">Taara Quest - Alpha Testing</div>
				<div className="chatContainerArea">
					<div className="leftChatContainerArea">
						{currentQuest ? (
							<>
								<div className="questInfo">
									<div className="questInfoTitle">Quest Info</div>
									<div className="questInfoElement">
										<Card sx={{ minWidth: 325 }}>
											<CardContent>
												<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
													Quest Data
												</Typography>
												<Typography variant="h5" component="div">
													{currentQuest.playerName}
												</Typography>
												<Typography sx={{ mb: 1.5 }} color="text.secondary">
													Current Player
												</Typography>
												<hr></hr>
												<Typography variant="body2">
													{`Roleplay Partner: ${
														findRoleplayPartnerObjectById()?.roleplayParterName
													}`}
												</Typography>
												<Typography variant="body2">
													{`Scenario: ${findScenarioByScenarioId()?.scenarioName}`}
												</Typography>
												<hr />
												<div className="promptHelpText">
													{
														"Please read through the scenario and open the conversation with John or Ella whenever you feel ready by  typing in the chat box. After 5-8 minutes of dialogue or when you feel you want to stop, please hit 'proceed to next stage' to start the reflection & feedback."
													}
												</div>
												<hr />
												<div className="scenarioPromptText">{`Scenario: ${
													findScenarioByScenarioId()?.prompt
												}`}</div>
											</CardContent>
										</Card>
									</div>
									{/* <div className="questInfoElement">
										<Box sx={{ width: 300 }}>
											<Typography id="input-slider" gutterBottom>
												Rate the quest
											</Typography>
											<Slider
												aria-label="Rating"
												defaultValue={5}
												getAriaValueText={valuetext}
												valueLabelDisplay="auto"
												shiftStep={1}
												step={1}
												marks
												min={0}
												max={5}
											/>
										</Box>
									</div>
									{currentStage === "coaching" ? (
										<>
											<div className="questInfoElement">
												<Box sx={{ width: 300 }}>
													<Typography id="input-slider" gutterBottom>
														Rate the coaching
													</Typography>
													<Slider
														aria-label="Coach Rating"
														defaultValue={5}
														getAriaValueText={coachValueText}
														valueLabelDisplay="auto"
														shiftStep={1}
														step={1}
														marks
														min={0}
														max={5}
													/>
												</Box>
											</div>
											<div className="questInfoElement">
												<textarea
													value={feedback}
													placeholder="Please Share your valuable feedback"
													onChange={(e) => {
														setFeedback(e.target.value);
													}}
												></textarea>
											</div>
										</>
									) : (
										<></>
									)} */}
									<div className="questInfoElement">
										{isEndingQuest ? (
											<>
												<LoaderAnimation />
											</>
										) : (
											<>
												{currentStage === "started" ? (
													<>
														<Button size="small" onClick={handleNextStage}>
															Proceed to next stage
														</Button>
													</>
												) : (
													<>
														<Button size="small" onClick={handleClickOpen}>
															End Quest
														</Button>
													</>
												)}
											</>
										)}
									</div>
								</div>
							</>
						) : (
							<>
								<div className="questData">
									<div className="questDataInput">
										<span>Alias</span>
										<input
											type="text"
											placeholder="Enter your alias"
											value={name}
											onChange={(e) => {
												setName(e.target.value);
											}}
										/>
									</div>
									{fetchingRoleplayPartners ? (
										<>
											<LoaderAnimation />
										</>
									) : (
										<>
											<div className="questDataInput">
												<Box
													sx={{
														minWidth: 150,
														width: {
															xs: "100%",
															lg: 350,
														},
													}}
												>
													<FormControl fullWidth>
														<InputLabel id="demo-simple-select-label">
															Select Age Range
														</InputLabel>
														<Select
															labelId="demo-simple-select-label"
															id="demo-simple-select"
															value={ageRange}
															label="Select Age Range"
															onChange={(e) => {
																setAgeRange(e.target.value);
															}}
														>
															{ageRangeOptions.map((ageOption) => {
																return (
																	<MenuItem value={ageOption}>{ageOption}</MenuItem>
																);
															})}
														</Select>
													</FormControl>
												</Box>
											</div>
											<div className="questDataInput">
												<Box
													sx={{
														minWidth: 150,
														width: {
															xs: "100%",
															lg: 350,
														},
													}}
												>
													<FormControl fullWidth>
														<InputLabel id="demo-simple-select-label">
															Select Gender
														</InputLabel>
														<Select
															labelId="demo-simple-select-label"
															id="demo-simple-select"
															value={gender}
															label="Select Gender"
															onChange={(e) => {
																setGender(e.target.value);
															}}
														>
															{genderOptions.map((genderOption) => {
																return (
																	<MenuItem value={genderOption}>
																		{genderOption}
																	</MenuItem>
																);
															})}
														</Select>
													</FormControl>
												</Box>
											</div>
											<div className="questDataInput">
												<Box
													sx={{
														minWidth: 150,
														width: {
															xs: "100%",
															lg: 350,
														},
													}}
												>
													<FormControl fullWidth>
														<InputLabel id="demo-simple-select-label">
															Select Location
														</InputLabel>
														<Select
															labelId="demo-simple-select-label"
															id="demo-simple-select"
															value={geography}
															label="Select Location"
															onChange={(e) => {
																setGeography(e.target.value);
															}}
														>
															{locationOptions.map((locationOption) => {
																return (
																	<MenuItem value={locationOption}>
																		{locationOption}
																	</MenuItem>
																);
															})}
														</Select>
													</FormControl>
												</Box>
											</div>
											{geography === "Other (please specify)" ? (
												<>
													<div className="questDataInput">
														<span>Location</span>
														<input
															type="text"
															placeholder="Enter your Location"
															value={specificLocation}
															onChange={(e) => {
																setSpecificLocation(e.target.value);
															}}
														/>
													</div>
												</>
											) : (
												<></>
											)}
											<div className="questDataInput">
												<Box
													sx={{
														minWidth: 150,
														width: {
															xs: "100%",
															lg: 350,
														},
													}}
												>
													<FormControl fullWidth>
														<InputLabel id="demo-simple-select-label">
															Select Educational Background
														</InputLabel>
														<Select
															labelId="demo-simple-select-label"
															id="demo-simple-select"
															value={education}
															label="Select Educational Background"
															onChange={(e) => {
																setEducation(e.target.value);
															}}
														>
															{educationOptions.map((locationOption) => {
																return (
																	<MenuItem value={locationOption}>
																		{locationOption}
																	</MenuItem>
																);
															})}
														</Select>
													</FormControl>
												</Box>
											</div>
											{education === "Other (please specify)" ? (
												<>
													<div className="questDataInput">
														<span>Education</span>
														<input
															type="text"
															placeholder="Please Specify"
															value={specificEducation}
															onChange={(e) => {
																setSpecificEducation(e.target.value);
															}}
														/>
													</div>
												</>
											) : (
												<></>
											)}
											<div className="questDataInput">
												<Box
													sx={{
														minWidth: 150,
														width: {
															xs: "100%",
															lg: 350,
														},
													}}
												>
													<FormControl fullWidth>
														<InputLabel id="demo-simple-select-label">
															Select Roleplay Partner
														</InputLabel>
														<Select
															labelId="demo-simple-select-label"
															id="demo-simple-select"
															value={roleplayPartnerId}
															label="Select Roleplay Partner"
															onChange={handleRoleplayPartnerChange}
														>
															{roleplayData.map((roleplayPartner) => {
																return (
																	<MenuItem value={roleplayPartner._id}>
																		{roleplayPartner.roleplayParterName}
																	</MenuItem>
																);
															})}
														</Select>
													</FormControl>
												</Box>
											</div>
										</>
									)}
									{fetchingScenarios ? (
										<>
											<LoaderAnimation />
										</>
									) : (
										<>
											<div className="questDataInput">
												<Box
													sx={{
														minWidth: 150,
														width: {
															xs: "100%",
															lg: 350,
														},
													}}
												>
													<FormControl fullWidth>
														<InputLabel id="demo-simple-select-label">
															Select a scenario
														</InputLabel>
														<Select
															labelId="demo-simple-select-label"
															id="demo-simple-select"
															value={scenarioId}
															label="Select a scenario"
															onChange={handleScenarioChange}
														>
															{getScenarios()}
														</Select>
													</FormControl>
												</Box>
											</div>
										</>
									)}
									<div className="startQuestArea">
										{errorMessage ? (
											<>
												<div className="errorMessage">{errorMessage}</div>
											</>
										) : (
											<></>
										)}
										{fetchingQuest ? (
											<>
												<LoaderAnimation />
											</>
										) : (
											<>
												<div
													className="startQuestButton"
													onClick={() => {
														setOpenStartQuestDialog(true);
													}}
												>
													Start Quest
												</div>
											</>
										)}
									</div>
								</div>
							</>
						)}
					</div>
					<div className="rightChatContainerArea">
						{/* TODO: This needs to be checked */}
						{currentQuest ? (
							<>
								{currentStage === "started" ? (
									<>
										<Chat />
									</>
								) : (
									<>
										<Coach />
									</>
								)}
							</>
						) : (
							<>
								<Video />
							</>
						)}
					</div>
					<Dialog
						open={open}
						onClose={handleClose}
						PaperProps={{
							component: "form",
							onSubmit: (event) => {
								event.preventDefault();
								const formData = new FormData(event.currentTarget);
								const formJson = Object.fromEntries(formData.entries());
								const email = formJson.email;
								console.log(email);
								handleClose();
							},
						}}
					>
						<DialogTitle>Subscribe</DialogTitle>
						<DialogContent>
							<DialogContentText>
								Please provide your ratings and feedback on the Quest and our coaching!
							</DialogContentText>
							<br />
							<br />
							<Typography id="input-slider" gutterBottom>
								Rate the roleplay conversation
							</Typography>
							<Slider
								aria-label="Rating"
								defaultValue={5}
								getAriaValueText={valuetext}
								valueLabelDisplay="auto"
								shiftStep={1}
								step={1}
								marks
								min={0}
								max={5}
							/>
							<Typography id="input-slider" gutterBottom>
								Rate the coaching conversation
							</Typography>
							<Slider
								aria-label="Coach Rating"
								defaultValue={5}
								getAriaValueText={coachValueText}
								valueLabelDisplay="auto"
								shiftStep={1}
								step={1}
								marks
								min={0}
								max={5}
							/>
							<TextField
								autoFocus
								margin="dense"
								label="Feedback"
								type="text"
								fullWidth
								// variant="standard"
								value={feedback}
								onChange={(e) => {
									setFeedback(e.target.value);
								}}
							/>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleClose}>Cancel</Button>
							<Button type="submit" onClick={handleEndQuest}>
								End Quest
							</Button>
						</DialogActions>
					</Dialog>
					<StartQuestPopup
						open={openStartQuestDialog}
						handleOpen={setOpenStartQuestDialog}
						startQuest={createQuest}
					></StartQuestPopup>
				</div>
			</div>
		</>
	);
};

export default Home;
