import { configureStore } from "@reduxjs/toolkit";
import { adminTabSlice } from "./slices/adminTab";
import { questSlice } from "./slices/quest";
import { questStageSlice } from "./slices/questStage";

export const store = configureStore({
	reducer: {
		quest: questSlice.reducer,
		questStage: questStageSlice.reducer,
		adminTab: adminTabSlice.reducer,
	},
});
