import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initializeStateFromSession = () => {
	const storedQuest = Cookies.get("currentUserTestQuest");
	return storedQuest ? "started" : null;
};

export const questStageSlice = createSlice({
	initialState: initializeStateFromSession(),
	name: "questStage",
	reducers: {
		beginQuest: (state) => {
			return "started";
		},
		beginCoaching: (state) => {
			return "coaching";
		},
		completedQuest: (state) => {
			return null;
		},
	},
});

export const { beginQuest, beginCoaching, completedQuest } = questStageSlice.actions;
export default questStageSlice.reducer;
