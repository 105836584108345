import React, { useEffect, useState } from "react";
import LoaderAnimation from "../LoaderAnimation/LoaderAnimation";
import "./Video.css";

const Video = () => {
	const [isLoading, setIsLoading] = useState(true);
	const youtubeEmbedCode = process.env.REACT_APP_VIDEO_URL;

	const handleVideoLoad = () => {
		setIsLoading(false);
	};

	useEffect(() => {
		const iframe = document.createElement("iframe");
		iframe.src = youtubeEmbedCode;
		iframe.onload = handleVideoLoad;
		document.body.appendChild(iframe);
		document.body.removeChild(iframe);
	}, [youtubeEmbedCode]);

	return (
		<div className="videoContainer">
			{isLoading && (
				<>
					<LoaderAnimation />
				</>
			)}
			<div className="videoTitle">
				Please watch this 7 min video of Leadership Coach Alexandria Scobee. Then start the roleplay through
				entering your data and selecting a roleplay plartner as well as a scenario. Try to apply the framework
				in the roleplay conversation but remember: <span>There is no perfect, Just Practice!</span>
			</div>
			<iframe
				width="640"
				height="480"
				src={youtubeEmbedCode}
				frameBorder="0"
				allowFullScreen
				allow="autoplay; encrypted-media; gyroscope; picture-in-picture"
				onLoad={handleVideoLoad}
			/>
		</div>
	);
};

export default Video;
