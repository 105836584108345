import React from "react";
import { useSelector } from "react-redux";
import "./MessageCard.css";

const MessageCard = ({ message, playerName }) => {
	const currentQuest = useSelector((state) => state.quest);

	const getAvatarContent = () => {
		if (message?.messageType === "user") {
			if (currentQuest) {
				return currentQuest.playerName.charAt(0).toUpperCase();
			} else {
				return playerName.charAt(0).toUpperCase();
			}
		}
		return "R";
	};

	return (
		<div className="messageCardContainer">
			{message.messageType === "user" ? (
				<>
					<div className="messageCardContent">{message.message}</div>
					<div className="messageCardAvatar">{getAvatarContent()}</div>
				</>
			) : (
				<>
					<div className="messageCardAvatar">{getAvatarContent()}</div>
					<div className="messageCardContent">{message.message}</div>
				</>
			)}
		</div>
	);
};

export default MessageCard;
