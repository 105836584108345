import { createSlice } from "@reduxjs/toolkit";

import Cookies from "js-cookie";

const initializeStateFromSession = () => {
	const storedQuest = Cookies.get("currentUserTestQuest");
	return storedQuest ? JSON.parse(storedQuest) : null;
};

export const questSlice = createSlice({
	initialState: initializeStateFromSession(),
	name: "currentQuest",
	reducers: {
		setQuest: (state, quest) => {
			Cookies.set("currentUserTestQuest", JSON.stringify(quest.payload));
			return quest.payload;
		},
		unsetQuest: (state) => {
			const currentQuest = initializeStateFromSession();
			if (currentQuest) {
				Cookies.remove("currentUserTestQuest");
			}

			return null;
		},
	},
});

export const { setQuest, unsetQuest } = questSlice.actions;
export default questSlice.reducer;
