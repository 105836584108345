import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ScrollToBottom from "react-scroll-to-bottom";
import LoaderAnimation from "../../components/LoaderAnimation/LoaderAnimation";
import MessageCard from "../../components/MessageCard/MessageCard";
import { setAdminTabToPartner, setAdminTabToQuest } from "../../redux/slices/adminTab";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import "./Admin.css";

const Admin = () => {
	const currentTab = useSelector((state) => state.adminTab);
	const [currentQuest, setCurrentQuest] = useState(null);
	const [totalQuestCount, setTotalQuestCount] = useState(null);
	const [messages, setMessages] = useState([]);
	const [quests, setQuests] = useState([]);
	const [showFetchIcon, setShowFetchIcon] = useState(false);
	const [scenarioData, setScenarioData] = useState([]);
	const [roleplayData, setRoleplayData] = useState([]);
	const [currentRoleplayPartner, setCurrentRoleplayPartner] = useState(null);
	const [currentScenario, setCurrentScenario] = useState(null);
	const [roleplayPartnerName, setRoleplayPartnerName] = useState(null);
	const [roleplayPartnerPrompt, setRoleplayPartnerPrompt] = useState(null);
	const [roleplayPartnerDesc, setRoleplayPartnerDesc] = useState(null);
	const [open, setOpen] = useState(false);
	const [editedRoleplayPartnerPrompt, setEditedRoleplayPartnerPrompt] = useState(null);
	const [editedRoleplayPartnerDescription, setEditedRoleplayPartnerDescription] = useState(null);
	const [editedScenarioName, setEditedScenarioName] = useState(null);
	const [editedScenarioPrompt, setEditedScenarioPrompt] = useState(null);
	const [updatingRoleplay, setUpdatingRoleplay] = useState(false);
	const [deletingRoleplay, setDeletingRoleplay] = useState(false);
	const [updatingScenario, setUpdatingScenario] = useState(false);
	const [deletingScenario, setDeletingScenario] = useState(false);
	const [addingScenario, setAddingScenario] = useState(false);
	const [isAddScenarioOpen, setIsAddScenarioOpen] = useState(false);
	const [addScenarioPrompt, setAddScenarioPrompt] = useState(null);
	const [addScenarioName, setAddScenarioName] = useState(null);

	const dispatch = useDispatch();

	const fetchMessages = () => {
		if (currentQuest && currentQuest._id) {
			const questsUrl = `${process.env.REACT_APP_BACKEND_URL}/api/messages/${currentQuest._id}`;
			axios
				.get(questsUrl)
				.then((response) => {
					setMessages(response.data.data);
				})
				.catch((error) => {});
		}
	};

	const formatDate = (dateString) => {
		const date = new Date(dateString);

		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const day = String(date.getDate()).padStart(2, "0");
		const hours = String(date.getHours()).padStart(2, "0");
		const minutes = String(date.getMinutes()).padStart(2, "0");

		return `${year}-${month}-${day} ${hours}:${minutes}`;
	};

	const fetchQuests = () => {
		const questsUrl = `${process.env.REACT_APP_BACKEND_URL}/api/game`;
		setShowFetchIcon(true);
		axios
			.get(questsUrl)
			.then((response) => {
				const { questData, totalQuests } = response.data.data;
				setTotalQuestCount(totalQuests);
				setQuests(questData);
				setShowFetchIcon(false);
			})
			.catch((error) => {
				setShowFetchIcon(false);
			});
	};

	const fetchData = async () => {
		const roleplayURL = `${process.env.REACT_APP_BACKEND_URL}/api/roleplayPartners`;
		const scenarioURL = `${process.env.REACT_APP_BACKEND_URL}/api/scenario`;

		axios
			.get(roleplayURL)
			.then((response) => {
				setRoleplayData(response.data.data);
			})
			.catch((error) => {});

		axios
			.get(scenarioURL)
			.then((response) => {
				setScenarioData(response.data.data);
			})
			.catch((error) => {});
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setRoleplayPartnerDesc(null);
		setRoleplayPartnerName(null);
		setRoleplayPartnerPrompt(null);
		setOpen(false);
	};

	useEffect(() => {
		setMessages([]);
		fetchMessages();
	}, [currentQuest]);

	const findRoleplayPartnerObjectById = (roleplayPartnerId) => {
		const partner = roleplayData.find((partner) => partner._id === roleplayPartnerId);
		return partner;
	};

	const findScenarioByScenarioId = (scenarioId) => {
		const scenario = scenarioData.find((scenario) => scenario._id === scenarioId);
		return scenario;
	};

	const createRoleplayPartner = () => {
		if (roleplayPartnerName && roleplayPartnerPrompt && roleplayPartnerDesc) {
			const URL = `${process.env.REACT_APP_BACKEND_URL}/api/roleplayPartners`;
			const payload = {
				roleplayParterName: roleplayPartnerName,
				prompt: roleplayPartnerPrompt,
				description: roleplayPartnerDesc,
			};

			axios
				.post(URL, payload)
				.then((response) => {
					fetchData();
					setOpen(false);
					setRoleplayPartnerDesc(null);
					setRoleplayPartnerName(null);
					setRoleplayPartnerPrompt(null);
				})
				.catch((error) => {
					setOpen(false);
					setRoleplayPartnerDesc(null);
					setRoleplayPartnerName(null);
					setRoleplayPartnerPrompt(null);
				});
		}
	};

	const getScenarios = () => {
		return scenarioData.map((scenario) => {
			if (scenario.roleplayPartnerId === currentRoleplayPartner?._id) {
				return <MenuItem value={scenario._id}>{scenario.scenarioName}</MenuItem>;
			}
		});
	};

	const handleScenarioChange = (e) => {
		const selectedScenario = findScenarioByScenarioId(e.target.value);
		setEditedScenarioName(selectedScenario?.scenarioName);
		setEditedScenarioPrompt(selectedScenario?.prompt);
		setCurrentScenario(selectedScenario);
	};

	const handleRoleplayPartnerDeletion = () => {
		if (currentRoleplayPartner) {
			setDeletingRoleplay(true);
			const URL = `${process.env.REACT_APP_BACKEND_URL}/api/roleplayPartners/${currentRoleplayPartner._id}`;
			axios
				.delete(URL)
				.then(() => {
					fetchData();
					setDeletingRoleplay(false);
					setCurrentRoleplayPartner(null);
				})
				.catch(() => {
					setDeletingRoleplay(false);
					setCurrentRoleplayPartner(null);
				});
		}
	};

	const handleRoleplayPartnerUpdation = () => {
		if (
			currentRoleplayPartner &&
			(currentRoleplayPartner?.prompt !== editedRoleplayPartnerPrompt ||
				currentRoleplayPartner?.description !== editedRoleplayPartnerDescription)
		) {
			setUpdatingRoleplay(true);
			const URL = `${process.env.REACT_APP_BACKEND_URL}/api/roleplayPartners`;
			const payload = {
				roleplayParterName: currentRoleplayPartner?.roleplayParterName,
				prompt: editedRoleplayPartnerPrompt,
				description: editedRoleplayPartnerDescription,
			};

			axios
				.patch(URL, payload)
				.then((response) => {
					fetchData()
						.then(() => {
							setUpdatingRoleplay(false);
							setCurrentRoleplayPartner(null);
						})
						.catch((error) => {
							setUpdatingRoleplay(false);
							setCurrentRoleplayPartner(null);
						});
				})
				.catch((error) => {
					setUpdatingRoleplay(false);
				});
		}
	};

	const handleScenarioUpdation = () => {
		if (
			currentScenario &&
			(currentScenario.prompt !== editedScenarioPrompt || currentScenario.name !== editedScenarioName)
		) {
			const URL = `${process.env.REACT_APP_BACKEND_URL}/api/scenario`;
			const payload = {
				scenarioId: currentScenario?._id,
				scenarioName: editedScenarioName,
				prompt: editedScenarioPrompt,
			};
			setUpdatingScenario(true);
			axios
				.patch(URL, payload)
				.then((response) => {
					fetchData()
						.then(() => {
							setUpdatingScenario(false);
							setCurrentScenario(null);
							setCurrentRoleplayPartner(null);
							setEditedScenarioName(null);
							setEditedScenarioPrompt(null);
						})
						.catch(() => {
							setUpdatingScenario(false);
							setCurrentRoleplayPartner(null);
							setCurrentScenario(null);
							setEditedScenarioName(null);
							setEditedScenarioPrompt(null);
						});
				})
				.catch(() => {
					setUpdatingScenario(false);
					setCurrentRoleplayPartner(null);
					setCurrentScenario(null);
				});
		}
	};

	const handleScenarioDeletion = () => {
		const URL = `${process.env.REACT_APP_BACKEND_URL}/api/scenario/${currentScenario._id}`;
		setDeletingScenario(true);
		axios
			.delete(URL)
			.then(() => {
				fetchData()
					.then(() => {
						setDeletingScenario(false);
						setCurrentScenario(null);
						setEditedScenarioName(null);
						setEditedScenarioPrompt(null);
					})
					.catch(() => {
						setDeletingScenario(false);
						setCurrentScenario(null);
						setEditedScenarioName(null);
						setEditedScenarioPrompt(null);
					});
			})
			.catch(() => {
				setDeletingScenario(false);
			});
	};

	const handleAddScenario = () => {
		if (addScenarioName && addScenarioPrompt) {
			const URL = `${process.env.REACT_APP_BACKEND_URL}/api/scenario/`;
			const payload = {
				roleplayPartnerId: currentRoleplayPartner?._id,
				scenarioName: addScenarioName,
				prompt: addScenarioPrompt,
			};
			setAddingScenario(true);
			axios
				.post(URL, payload)
				.then((response) => {
					fetchData()
						.then(() => {
							setAddScenarioName(null);
							setAddScenarioPrompt(null);
							setAddingScenario(false);
							setCurrentScenario(null);
							setEditedScenarioName(null);
							setEditedScenarioPrompt(null);
							setIsAddScenarioOpen(false);
						})
						.catch(() => {
							setAddScenarioName(null);
							setAddScenarioPrompt(null);
							setAddingScenario(false);
							setCurrentScenario(null);
							setEditedScenarioName(null);
							setEditedScenarioPrompt(null);
							setIsAddScenarioOpen(false);
						});
				})
				.catch(() => {
					setAddingScenario(false);
					setAddScenarioName(null);
					setAddScenarioPrompt(null);
					setIsAddScenarioOpen(false);
				});
		}
	};

	useEffect(() => {
		fetchQuests();
		fetchData();
	}, [currentTab]);

	return (
		<div className="adminContainer">
			<div className="adminContainerHeading">Taara Quest Alpha - Admin Console</div>
			<hr />
			<div className="adminContainerArea">
				<div className="adminTopbar">
					<div
						className="adminTopbarLink"
						onClick={() => {
							dispatch(setAdminTabToQuest());
						}}
					>
						Quests
					</div>
					<div
						className="adminTopbarLink"
						onClick={() => {
							dispatch(setAdminTabToPartner());
						}}
					>
						Roleplay
					</div>
				</div>
				<div className="adminContent">
					{currentTab === "quest" ? (
						<>
							<div className="QuestDataContainer">
								<div className="questListContainer">
									{showFetchIcon ? (
										<>
											<LoaderAnimation />
										</>
									) : (
										<>
											{quests.map((quest) => {
												return (
													<div
														className={
															currentQuest?._id === quest._id ? "quest selected" : "quest"
														}
														onClick={() => {
															setCurrentQuest(quest);
														}}
													>
														<div className="playerName">{quest?.playerName}</div>
														<div className="ratingArea">
															<div className="coachRating">{`Quest Rating : ${quest.rating} / 5`}</div>
															<div className="questRating">{`Coach Rating : ${quest.coachRating} / 5`}</div>
														</div>
														<div className="completionStatus">{`Quest Completed: ${quest.completed}`}</div>
														<div className="creationDate">
															{formatDate(quest.createdAt)}
														</div>
													</div>
												);
											})}
										</>
									)}
								</div>
								<div className="questMessageContainer">
									{currentQuest ? (
										<>
											<div className="questMetadata">
												<div className="questMetadataTitle">Quest Info</div>
												<div className="questMetadataMetric">
													<div className="questMetadataKey">Roleplay Partner</div>
													<div className="questMetadataValue">
														{
															findRoleplayPartnerObjectById(
																currentQuest.roleplayPartnerId
															).roleplayParterName
														}
													</div>
												</div>
												<div className="questMetadataMetric">
													<div className="questMetadataKey">Roleplay Scenario</div>
													<div className="questMetadataValue">
														{
															findScenarioByScenarioId(currentQuest.scenarioId)
																?.scenarioName
														}
													</div>
												</div>
												<div className="questMetadataMetric">
													<div className="questMetadataKey">Age Range</div>
													<div className="questMetadataValue">{currentQuest.ageRange}</div>
												</div>
												<div className="questMetadataMetric">
													<div className="questMetadataKey">Location</div>
													<div className="questMetadataValue">{currentQuest.geography}</div>
												</div>
												<div className="questMetadataMetric">
													<div className="questMetadataKey">Education</div>
													<div className="questMetadataValue">{currentQuest.education}</div>
												</div>
												<div className="questMetadataMetric">
													<div className="questMetadataKey">Gender</div>
													<div className="questMetadataValue">{currentQuest.gender}</div>
												</div>
												<div className="questMetadataMetric">{currentQuest.feedback || ""}</div>
											</div>
											<div className="questMessages">
												{messages ? (
													<>
														<ScrollToBottom>
															{messages.map((msg) => {
																let className = "messageCard alignRight";
																if (msg.messageType !== "user") {
																	className = "messageCard alignLeft";
																}
																return (
																	<>
																		<div className={className}>
																			<MessageCard
																				message={msg}
																				playerName={currentQuest.playerName}
																			/>
																		</div>
																	</>
																);
															})}
														</ScrollToBottom>
													</>
												) : (
													<></>
												)}
											</div>
										</>
									) : (
										<></>
									)}
								</div>
							</div>
						</>
					) : (
						<>
							<div className="roleplayDataContainer">
								<div className="roleplayPartnerList">
									<div className="roleplayPartnerCard" onClick={handleClickOpen}>
										Add Roleplay Partner
									</div>
									{roleplayData.map((partner) => {
										return (
											<div
												className={
													currentRoleplayPartner?._id === partner?._id
														? "roleplayPartnerCard selectedRoleplayPartnerCard"
														: "roleplayPartnerCard"
												}
												onClick={() => {
													setCurrentRoleplayPartner(() => partner);
													setEditedRoleplayPartnerPrompt(() => partner.prompt);
													setEditedRoleplayPartnerDescription(() => partner.description);
													setEditedScenarioName(() => null);
													setEditedScenarioPrompt(() => null);
													setCurrentScenario(() => null);
												}}
											>
												{partner?.roleplayParterName}
											</div>
										);
									})}
								</div>
								{currentRoleplayPartner ? (
									<>
										<div className="roleplayScenarios">
											<div className="roleplayControls">
												<div className="roleplayScenariosTitle">Roleplay Controls</div>
												<div className="roleplayControlElement">
													<div className="roleplayControlKey">Prompt</div>
													<div className="roleplayControlValue">
														<textarea
															value={editedRoleplayPartnerPrompt}
															onChange={(e) => {
																setEditedRoleplayPartnerPrompt(e.target.value);
															}}
														></textarea>
													</div>
												</div>
												<div className="roleplayControlElement">
													<div className="roleplayControlKey">Description</div>
													<div className="roleplayControlValue">
														<textarea
															value={editedRoleplayPartnerDescription}
															onChange={(e) => {
																setEditedRoleplayPartnerDescription(e.target.value);
															}}
														></textarea>
													</div>
												</div>
												<div className="roleplayControlActions">
													{updatingRoleplay ? (
														<>
															<LoaderAnimation />
														</>
													) : (
														<>
															<div
																className="roleplayContolAction"
																onClick={handleRoleplayPartnerUpdation}
															>
																<i className="fa-solid fa-pencil"></i>Update Roleplay
																Partner
															</div>
														</>
													)}
													{deletingRoleplay ? (
														<>
															<LoaderAnimation />{" "}
														</>
													) : (
														<>
															<div
																className="roleplayContolAction"
																onClick={handleRoleplayPartnerDeletion}
															>
																<i className="fa-solid fa-trash"></i>Delete Roleplay
																Partner
															</div>
														</>
													)}
												</div>
											</div>
											<div className="scenarioControls">
												<div className="roleplayScenariosTitle">Scenario Controls</div>
												<Box
													sx={{
														minWidth: 150,
														width: {
															xs: "100%",
															lg: 350,
														},
													}}
													marginBottom={2}
												>
													<FormControl fullWidth>
														<InputLabel id="demo-simple-select-label">
															Select a scenario to Edit/Delete
														</InputLabel>
														<Select
															labelId="demo-simple-select-label"
															id="demo-simple-select"
															value={currentScenario?.scenarioName}
															label="Select a scenario"
															onChange={handleScenarioChange}
														>
															{getScenarios()}
														</Select>
													</FormControl>
												</Box>
												{currentScenario ? (
													<>
														<div className="roleplayControlElement">
															<div className="roleplayControlKey">Scenario Name</div>
															<div className="roleplayControlValue">
																<textarea
																	value={editedScenarioName}
																	onChange={(e) => {
																		setEditedScenarioName(e.target.value);
																	}}
																></textarea>
															</div>
														</div>
														<div className="roleplayControlElement">
															<div className="roleplayControlKey">Scenario Prompt</div>
															<div className="roleplayControlValue">
																<textarea
																	value={editedScenarioPrompt}
																	onChange={(e) => {
																		setEditedScenarioPrompt(e.target.value);
																	}}
																></textarea>
															</div>
														</div>
														<div className="roleplayControlActions">
															{updatingScenario ? (
																<>
																	<LoaderAnimation />
																</>
															) : (
																<>
																	<div
																		className="roleplayContolAction"
																		onClick={handleScenarioUpdation}
																	>
																		<i className="fa-solid fa-pencil"></i>Update
																		Scenario
																	</div>
																</>
															)}
															{deletingScenario ? (
																<>
																	<LoaderAnimation />{" "}
																</>
															) : (
																<>
																	<div
																		className="roleplayContolAction"
																		onClick={handleScenarioDeletion}
																	>
																		<i className="fa-solid fa-trash"></i>Delete
																		Scenario
																	</div>
																</>
															)}
														</div>
													</>
												) : (
													<>
														<div className="ScenarioAddition">
															{addingScenario ? (
																<>
																	<LoaderAnimation />
																</>
															) : (
																<>
																	<div
																		className="roleplayContolActionButton"
																		onClick={() => {
																			setIsAddScenarioOpen(true);
																		}}
																	>
																		<i className="fa-solid fa-plus"></i>Add Scenario
																	</div>
																</>
															)}
														</div>
													</>
												)}
											</div>
										</div>
									</>
								) : (
									<></>
								)}
							</div>
						</>
					)}
				</div>
				{/* To Rate Quests */}
				<Dialog
					open={open}
					onClose={handleClose}
					PaperProps={{
						component: "form",
						onSubmit: (event) => {
							event.preventDefault();
							const formData = new FormData(event.currentTarget);
							const formJson = Object.fromEntries(formData.entries());
							const email = formJson.email;
							console.log(email);
							handleClose();
						},
					}}
				>
					<DialogTitle>Create a Roleplay Partner</DialogTitle>
					<DialogContent>
						<br />
						<TextField
							autoFocus
							margin="dense"
							label="Roleplay Partner Name"
							type="text"
							fullWidth
							// variant="standard"
							value={roleplayPartnerName}
							onChange={(e) => {
								setRoleplayPartnerName(e.target.value);
							}}
						/>
						<TextField
							autoFocus
							margin="dense"
							label="Roleplay Partner Prompt"
							type="text"
							fullWidth
							// variant="standard"
							value={roleplayPartnerPrompt}
							onChange={(e) => {
								setRoleplayPartnerPrompt(e.target.value);
							}}
						/>
						<TextField
							autoFocus
							margin="dense"
							label="Roleplay Partner Description"
							type="text"
							fullWidth
							// variant="standard"
							value={roleplayPartnerDesc}
							onChange={(e) => {
								setRoleplayPartnerDesc(e.target.value);
							}}
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose}>Cancel</Button>
						<Button type="submit" onClick={createRoleplayPartner}>
							Create a roleplay Partner
						</Button>
					</DialogActions>
				</Dialog>
				{/* To Add a Scenario */}
				<Dialog
					open={isAddScenarioOpen}
					onClose={() => {
						setIsAddScenarioOpen(false);
					}}
				>
					<DialogTitle>Create a Roleplay Scenario</DialogTitle>
					<DialogContent>
						<br />
						<TextField
							autoFocus
							margin="dense"
							label="Roleplay Scenario Name"
							type="text"
							fullWidth
							// variant="standard"
							value={addScenarioName}
							onChange={(e) => {
								setAddScenarioName(e.target.value);
							}}
						/>
						<TextField
							autoFocus
							margin="dense"
							label="Roleplay Scenario Prompt"
							type="text"
							fullWidth
							// variant="standard"
							value={addScenarioPrompt}
							onChange={(e) => {
								setAddScenarioPrompt(e.target.value);
							}}
						/>
					</DialogContent>
					<DialogActions>
						{addingScenario ? (
							<>
								<LoaderAnimation />
							</>
						) : (
							<>
								<Button
									onClick={() => {
										setIsAddScenarioOpen(false);
									}}
								>
									Cancel
								</Button>
								<Button type="submit" onClick={handleAddScenario}>
									Add Scenario
								</Button>
							</>
						)}
					</DialogActions>
				</Dialog>
			</div>
		</div>
	);
};

export default Admin;
